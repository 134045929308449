/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Besinnliche Weihnachten mit der Familie - in diesem Social Media Werbespot für die Musik-App Audanika der Grace Cloud GmbH sollen die Bilder sprechen und Gefühle von Wärme, Kindlichkeit und weihnachtlicher Vorfreude wecken. Es soll der Wunsch aufkommen,  an den Feiertagen mit der eigenen Familie gemeinsam Zeit zu verbringen."), "\n", React.createElement(_components.p, null, "Dieser Film wirkt nur in der Adventszeit, da ein gewisses Priming nötig ist, was im Dezember durch die  äußeren Einflüsse automatisch entsteht. Dieses Vorgefühl baut das Video auf, lässt es wellenartig anschwellen, um den Zuschauer emotional zu berühren."), "\n", React.createElement(_components.p, null, "Um besonders authentisch zu sein, ist der App-Entwickler selbst der Familienvater und Sprecher des Videos. Wir haben mit seinen eigenen Kindern gedreht, die sich als hervorragende Darsteller erwiesen. Sie haben die Kamera schnell vergessen und hatten einfach richtig viel Spaß beim Dreh."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
